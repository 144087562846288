<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->
      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
      <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />
      <v-card
        elevation="0"
        v-if="
          permissions && permissions.documents && permissions.documents.canView
        "
      >
        <v-card-title>Documents</v-card-title>
        <v-card-actions
          v-if="
            permissions &&
              permissions.documents &&
              permissions.documents.canUpload
          "
        >
          <router-link :to="`/customerView/${customerId}/documents/new`">
            <v-btn small class="orange-bg white--text">
              Upload New Document
            </v-btn>
          </router-link>
        </v-card-actions>
        <v-card-text>
          <!-- Start Documents section -->
          <v-data-table
            :headers="headers"
            :items="records"
            item-key="id"
            :loading="isLoading"
            :server-items-length="totalRecords"
            :options.sync="options"
            loading-text="Loading... Please wait"
            class="elevation-1"
          >
            <template #[`item.fileName`]="{ item }">
              <a target="_blank" :href="item.fileUrl">{{ item.fileName }}</a>
            </template>
          </v-data-table>

          <!-- End Documents section -->
        </v-card-text>
      </v-card>
      <!-- </v-container> -->
    </v-skeleton-loader>
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/customerView/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import { GET_QUERY, API_CUSTOMERS } from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
// import { API_USERS } from "@/core/store/crud.module";
import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";

// import { required } from "vuelidate/lib/validators";
// import moment from "moment";
import permissionsHelper from "@/core/helpers/permissionsHelper";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs
  },
  data: () => ({
    headers: [
      { text: "Name", value: "fileName", class: "orange-bg" },
      { text: "Type", value: "documentType", class: "orange-bg" }
    ],
    communityInfo: {},
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ["fileName"],
      sortDesc: [true]
    },

    pageText: "",
    startingAfter: "",
    endingBefore: "",
    previousPage: 1,
    hasMore: false,
    isLoading: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: [],
    records: [],
    totalRecords: 0,
    customDateMenu: false,
    customDates: [],
    formattedCustomDates: "",
    permissions: {}
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    return true;
    // return {
    //   billingInfo: {
    //     subscription: {
    //       nextBillingDates: {
    //         required,
    //         minDate: value =>
    //           dateFormatter.convertToMilliseconds(value) >
    //           dateFormatter.convertToMilliseconds(new Date())
    //       }
    //     }
    //   }
    // };
  },
  watch: {
    // options: async function() {
    //   this.isLoading = true;
    //   if (this.previousPage < this.options.page && this.options.page !== 1) {
    //     this.setStartingAfter();
    //   } else if (
    //     this.previousPage > this.options.page &&
    //     this.options.page !== 1
    //   ) {
    //     this.setEndingBefore();
    //   } else {
    //     this.startingAfter = "";
    //     this.endingBefore = "";
    //   }
    //   this.isLoading = false;
    // }
    "options.sortBy": async function() {
      await this.getRecords();
    }
  },
  async mounted() {
    this.getRecords();
  },
  async created() {
    await this.getComunityInfo();
    await permissionsHelper.getPermissions().then(this.getPermissions);
  },
  methods: {
    getPermissions(permissionsObj) {
      this.permissions = permissionsObj;
    },
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerDetails",
        params: { customerId: communityId }
      });
    },
    async getRecords() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.isLoading = true;

      let self = this;
      let url = `${API_CUSTOMERS}/${this.customerId}/documents`;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: url
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            let items = response.data.$values;

            // items.forEach(el => {
            //   el.approveDialog = false;
            //   el.approveProcessing = false;
            // });

            if (sortBy.length === 1 && sortDesc.length === 1) {
              items = items.sort((a, b) => {
                const sortA = a[sortBy[0]];
                const sortB = b[sortBy[0]];

                if (sortDesc[0]) {
                  if (sortA < sortB) return 1;
                  if (sortA > sortB) return -1;
                  return 0;
                } else {
                  if (sortA < sortB) return -1;
                  if (sortA > sortB) return 1;
                  return 0;
                }
              });
            }

            if (itemsPerPage > 0) {
              items = items.slice(
                (page - 1) * itemsPerPage,
                page * itemsPerPage
              );
            }

            self.records = items;
            self.totalRecords = self.records.length;
          } else {
            self.$snackbar.showMessage({
              content:
                response.data ||
                "Cannot receive a list of documents. Something went wrong!",
              color: "red"
            });
          }

          self.isLoading = false;
        });
    },
    async getComunityInfo() {
      this.loading = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            this.communityInfo = crud.getPopulatedComunityInfo(response.data);

            this.createBreadcrumb();
            this.setInternalBreadcrumbs();
          } else {
            this.$snackbar.showMessage({
              content:
                response.data ||
                "Cannot get community info. Something went wrong!",
              color: "red"
            });
          }

          this.loading = false;
        });
    },
    async setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customerView/" + this.customerId
        },
        { title: "Documents" }
      ];
    },

    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.communityInfo.name,
          route: "/customerView/" + this.customerId
        },
        { title: "Documents" }
      ]);
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
    },
    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      // return moment(date).format("MM/DD/YYYY");
      return dateFormatter.formatDateUs(date);
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    }
  },
  computed: {}
};
</script>
