<template>
  <div>
    <v-card outlined elevation="0" class="service-box">
      <h6 class="orange-bg white--text text-center py-1">Service Center</h6>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" lg="4" md="6" xs="12">
            <div>
              <router-link
                v-if="
                  permissions &&
                    permissions.ninetyDayPlan &&
                    permissions.ninetyDayPlan.canView
                "
                :to="`/customerView/${id}/plans`"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  :href="href"
                  class="service-box-link"
                  :class="[
                    isActive && 'service-box-item-active',
                    isExactActive && 'service-box-item-active'
                  ]"
                  @click="navigate"
                >
                  Quarterly Plans
                </a></router-link
              >
            </div>
            <div>
              <router-link
                v-if="
                  permissions &&
                    permissions.orangeReport &&
                    permissions.orangeReport.canView
                "
                :to="`/customerView/${id}/orange-reports`"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  :href="href"
                  class="service-box-link"
                  :class="[
                    isActive && 'service-box-item-active',
                    isExactActive && 'service-box-item-active'
                  ]"
                  @click="navigate"
                >
                  Orange Reports
                </a></router-link
              >
            </div>
            <div>
              <router-link
                v-if="
                  permissions &&
                    permissions.message &&
                    permissions.message.canView
                "
                :to="`/customerView/${id}/messages`"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  :href="href"
                  class="service-box-link"
                  :class="[
                    isActive && 'service-box-item-active',
                    isExactActive && 'service-box-item-active'
                  ]"
                  @click="navigate"
                >
                  Messages
                </a></router-link
              >
            </div>
          </v-col>
          <v-col cols="12" lg="4" md="6" xs="12">
            <div>
              <router-link
                v-if="
                  permissions &&
                    permissions.proposal &&
                    permissions.proposal.canView
                "
                :to="`/customerView/${id}/proposals`"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  :href="href"
                  class="service-box-link"
                  :class="[
                    isActive && 'service-box-item-active',
                    isExactActive && 'service-box-item-active'
                  ]"
                  @click="navigate"
                >
                  Proposals
                </a></router-link
              >
            </div>
            <div>
              <router-link
                v-if="
                  permissions &&
                    permissions.invoice &&
                    permissions.invoice.canView
                "
                :to="`/customerView/${id}/invoices`"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  :href="href"
                  class="service-box-link"
                  :class="[
                    isActive && 'service-box-item-active',
                    isExactActive && 'service-box-item-active'
                  ]"
                  @click="navigate"
                >
                  Invoices
                </a></router-link
              >
            </div>
          </v-col>
          <v-col cols="12" lg="4" md="6" xs="12">
            <div>
              <router-link
                v-if="
                  permissions &&
                    permissions.documents &&
                    permissions.documents.canView
                "
                :to="`/customerView/${id}/documents`"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  :href="href"
                  class="service-box-link"
                  :class="[
                    isActive && 'service-box-item-active',
                    isExactActive && 'service-box-item-active'
                  ]"
                  @click="navigate"
                >
                  Documents
                </a></router-link
              >
            </div>
            <div>
              <router-link
                v-if="
                  permissions &&
                    permissions.manageUsers &&
                    permissions.manageUsers.canAccess
                "
                :to="`/customerView/${id}/manage-portal-access`"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  :href="href"
                  class="service-box-link"
                  :class="[
                    isActive && 'service-box-item-active',
                    isExactActive && 'service-box-item-active'
                  ]"
                  @click="navigate"
                >
                  Manage Users
                </a></router-link
              >
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import permissionsHelper from "@/core/helpers/permissionsHelper";

export default {
  name: "CustomerViewServiceCenterBox",
  props: {
    id: [String, Number]
  },
  data: () => ({
    hasNewMessage: false,
    permissions: []
  }),
  async mounted() {
    permissionsHelper.getPermissions().then(this.getPermissions);
  },
  methods: {
    getPermissions(permissionsObj) {
      this.permissions = permissionsObj;
    }
  }
};
</script>
