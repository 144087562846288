<template>
  <div id="syncscape_communityInfo">
    <CommunityHeader
      :communityName="communityInfo.name"
      :temperature="temperature"
      :humidity="humidity"
      :loading="weatherLoading || communityInfo == null"
      :weatherCode="weatherCode"
      :isInternalView="false"
      :isResidentView="isResident"
      :communityId="communityInfo.aspireId"
    />
    <div cols="12" md="2" v-if="relatedCommunityItems.length > 1">
      <v-select
        v-model="relatedCommunity"
        :items="relatedCommunityItems"
        item-text="communityName"
        item-value="aspireId"
        label="Select Related Community..."
      >
      </v-select>
    </div>
    <v-card elevation="0">
      <v-card-text>
        <v-row>
          <v-col>
            <AccountManagerInfo :communityInfo="communityInfo" />
          </v-col>
        </v-row>
        <v-row v-if="userInfo && !isResident">
          <v-col>
            <CommunitySentiment :communityInfo="communityInfo" />
          </v-col>
          <v-col cols="12" md="2" sm="6" lg="1">
            <div>
              <h6 class="text-center">Service Status</h6>
            </div>
            <div class="text-center" v-if="serviceStatusLoading">
              <v-progress-circular
                v-if="serviceStatusLoading"
                :active="serviceStatusLoading"
                :indeterminate="serviceStatusLoading"
                color="primary"
              ></v-progress-circular>
            </div>
            <div v-if="!serviceStatusLoading">
              <div class="mx-auto" style="width: 50px;">
                <v-img
                  v-model="serviceStatus.status"
                  :src="getServiceStatusIconPath(serviceStatus)"
                ></v-img>
              </div>
              <p class="text-center orange--text mt-3 mb-0">
                {{ serviceStatus.status }}
              </p>
              <p class="text-center mt-0" v-if="serviceStatus.rescheduledText">
                {{ serviceStatus.rescheduledText }}
              </p>
              <!-- <p
              class="text-center mt-0"
              v-if="communityInfo.serviceStatus.noServiceThisWeek"
            >
              No service this week
            </p>
            <p
              class="text-center mt-0"
              v-else-if="communityInfo.serviceStatus.rescheduleDate"
            >
              Rescheduled
              {{ formatDateUs(communityInfo.serviceStatus.rescheduleDate) }}
            </p> -->
            </div>
          </v-col>

          <v-col justify="space-around" cols="12" md="5" lg="4">
            <h6 class="font-weight-bold text-center">
              <span>Service Request</span>&nbsp;
              <router-link
                class="blue-color"
                :to="`/customerView/${communityInfo.id}/requests`"
                v-slot="{ href, navigate, isExactActive }"
              >
                <a
                  :href="href"
                  class="link"
                  :class="[isExactActive && 'link-active']"
                  @click="navigate"
                >
                  View All
                </a></router-link
              >
            </h6>
            <v-row justify="center">
              <v-col class="text-center" md="4" lg="4" sm="4">
                <h1 class="text-center" style="color: #df6526;">
                  <v-progress-circular
                    v-if="requestsLoading"
                    :active="requestsLoading"
                    :indeterminate="requestsLoading"
                    color="primary"
                  ></v-progress-circular>
                  <span v-if="!requestsLoading">
                    {{
                      this.communityInfo.serviceRequestsNumbers.open
                      /*communityInfo.serviceRequests.filter(el => el.isOpen || el.isInProcess).length*/
                    }}
                  </span>
                </h1>
                <span class="text-center">Open</span>
              </v-col>
              <v-col class="text-center" md="4" lg="4" sm="4">
                <h1 class="text-center" style="color: #df6526;">
                  <v-progress-circular
                    v-if="requestsLoading"
                    :active="requestsLoading"
                    :indeterminate="requestsLoading"
                    color="primary"
                  ></v-progress-circular>
                  <span v-if="!requestsLoading">
                    {{
                      this.communityInfo.serviceRequestsNumbers.inProcess
                      /*communityInfo.serviceRequests.filter(el => el.isOpen || el.isInProcess).length*/
                    }}
                  </span>
                </h1>
                <span class="text-center">In Process</span>
              </v-col>
              <v-col class="text-center" md="4" lg="4" sm="4">
                <h1 class="text-cener" style="color: #31c0e3;">
                  <v-progress-circular
                    v-if="requestsLoading"
                    :active="requestsLoading"
                    :indeterminate="requestsLoading"
                    color="primary"
                  ></v-progress-circular>
                  <span v-if="!requestsLoading">
                    {{
                      this.communityInfo.serviceRequestsNumbers.closed
                      /*communityInfo.serviceRequests.filter(el => el.isCompleted).length*/
                    }}
                  </span>
                </h1>
                <span class="text-center">Closed</span>
              </v-col>
            </v-row>
            <v-row justify="space-around">
              <v-col class="text-center">
                <router-link
                  v-if="
                    permissions &&
                      permissions.serviceRequest &&
                      permissions.serviceRequest.canCreate
                  "
                  :to="`/customerView/${communityInfo.id}/requests/new`"
                >
                  <v-btn small rounded class="white--text blue-bg">
                    New Service Request
                  </v-btn>
                </router-link>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <ServiceCenterBox :id="communityInfo.id" />
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="6" md="3" sm="4" lg="2">
            <div>
              <div class="mx-auto" style="width: 50px;">
                <v-img src="/media/assets/icons/icons-service.png"></v-img>
              </div>
              <p class="text-center mt-2 mb-0">
                <router-link
                  :to="`/customerView/${communityInfo.id}/requests`"
                  class="link"
                >
                  <v-btn class="white--text" small>
                    Service Requests
                  </v-btn>
                  <!-- <a
                    :href="href"
                    class="link"
                    :class="[
                      isActive && 'link-active',
                      isExactActive && 'link-active'
                    ]"
                    @click="navigate"
                  >
                    Service Requests
                  </a> -->
                </router-link>
              </p>
            </div>
          </v-col>
          <v-col cols="6" md="3" sm="4" lg="2">
            <div>
              <div class="mx-auto" style="width: 50px;">
                <v-img src="/media/assets/icons/icons-scheduleDocs.png"></v-img>
              </div>
              <p class="text-center mt-2 mb-0">
                <router-link
                  v-if="
                    permissions &&
                      permissions.documents &&
                      permissions.documents.canView
                  "
                  :to="`/customerView/${communityInfo.id}/documents`"
                  class="link"
                >
                  <v-btn class="white--text" small>
                    Schedules & Docs
                  </v-btn>
                  <!-- <a
                    :href="href"
                    class="link"
                    :class="[
                      isActive && 'link-active',
                      isExactActive && 'link-active'
                    ]"
                    @click="navigate"
                  >
                    Schedules & Docs
                  </a> -->
                </router-link>
              </p>
            </div>
          </v-col>
          <v-col cols="6" md="3" sm="4" lg="2">
            <div>
              <div class="mx-auto" style="width: 50px;">
                <v-img src="/media/assets/icons/icons-message.png"></v-img>
              </div>
              <p class="text-center mt-2 mb-0">
                <router-link
                  v-if="
                    permissions &&
                      permissions.message &&
                      permissions.message.canView
                  "
                  :to="`/customerView/${communityInfo.id}/messages`"
                  class="link"
                >
                  <v-btn class="white--text" small>
                    Messages
                  </v-btn>
                </router-link>
              </p>
            </div>
          </v-col>
          <v-col cols="6" md="3" sm="4" lg="2">
            <div>
              <div class="mx-auto" style="width: 50px;">
                <v-img src="/media/assets/icons/icons-kudos.png"></v-img>
              </div>
              <p class="text-center mt-2 mb-0">
                <router-link
                  :to="`/customerView/${communityInfo.id}/sendKudos`"
                  class="link"
                >
                  <v-btn class="white--text" small>
                    Send Kudos
                  </v-btn>
                  <!-- <a
                    :href="href"
                    class="link"
                    :class="[
                      isActive && 'link-active',
                      isExactActive && 'link-active'
                    ]"
                    @click="navigate"
                  >
                    Send Kudos
                  </a> -->
                </router-link>
              </p>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";

import CommunityHeader from "@/view/components/CommunityHeader.vue";
import CommunitySentiment from "@/view/components/customerView/CommunitySentiment.vue";
import AccountManagerInfo from "@/view/components/customerView/AccountManagerInfo.vue";
import ServiceCenterBox from "@/view/components/customerView/ServiceCenterBox.vue";
import permissionsHelper from "@/core/helpers/permissionsHelper";
import Mgr from "@/core/services/security.service";
// import moment from "moment";
import {
  GET_QUERY,
  API_WEATHER,
  API_SERVICE_REQUESTS,
  API_CUSTOMERS
} from "@/core/store/crud.module";

export default {
  name: "CustomerViewCommunityInfo",
  components: {
    CommunityHeader,
    CommunitySentiment,
    ServiceCenterBox,
    AccountManagerInfo
  },
  data: () => ({
    mgr: new Mgr(),
    userInfo: null,
    permissions: {},
    serviceStatusDialog: false,
    serviceAlert: {
      reason: "",
      target: "",
      date: null,
      date2: null
    },
    serviceStatus: {},
    serviceStatusLoading: false,
    serviceAlertDateItems: [
      {
        name: "Next business day",
        value: "Next business day"
      },
      {
        name: "2 days later",
        value: "2 days later"
      },
      {
        name: "No service this week",
        value: "No service this week"
      }
    ],
    relatedCommunity: null,
    relatedCommunityItems: [],
    alertMenu: false,
    formattedAlertDate: null,
    alertDate: null,
    isLoading: false,
    loading: false,
    weatherLoading: false,
    relatedLoading: false,
    temperature: 0,
    humidity: 0,
    weatherCode: 800,
    requestsLoading: false
  }),
  props: {
    communityInfo: Object
    // relatedCommunityItems: Array
  },
  async mounted() {
    this.userInfo = await this.mgr.getUser();
    await this.getWeather();
    await this.getRelatedComunities();
    await this.getServiceRequests();
    await this.getServiceStatus();
  },
  async created() {
    permissionsHelper.getPermissions().then(this.getPermissions);
  },
  watch: {
    relatedCommunity: async function() {
      // let path = "/customers/" + this.relatedCommunity;
      // this.$router.push(path);
      this.$emit("onRelatedCommunityChanged", this.relatedCommunity);
      // this.$router.push({
      //   name: "CustomerDetails",
      //   params: { customerId: this.relatedCommunity }
      // });
    },
    alertDate: function() {
      this.formattedAlertDate = this.formatDate(this.alertDate);
    }
  },
  methods: {
    getPermissions(permissionsObj) {
      this.permissions = permissionsObj;
    },
    async getRelatedComunities() {
      if (!this.communityInfo || !this.communityInfo.aspireId) return;
      this.relatedLoading = true;
      this.$emit("onRelatedCommunityLoading");
      let self = this;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: `${API_CUSTOMERS}/${self.communityInfo.aspireId}/relatedCustomers`
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            self.relatedCommunityItems = response.data;
          }
          self.relatedLoading = false;
          self.$emit("onRelatedCommunityLoaded", self.relatedCommunityItems);
        });
    },
    async getServiceStatus() {
      if (!this.communityInfo || !this.communityInfo.aspireId) return;

      if (this.serviceStatusLoading) return;

      this.serviceStatusLoading = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: `${API_CUSTOMERS}/${this.communityInfo.aspireId}/serviceStatus`
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.serviceStatus = response.data;
            if (!this.serviceStatus) {
              this.serviceStatus = {
                status: "On-Schedule",
                rescheduledDate: null,
                noServiceThisWeek: false
              };
            }
          }
          // else {
          // }
          this.serviceStatusLoading = false;
        });
    },
    async getWeather() {
      if (this.weatherLoading) return;

      if (
        !this.communityInfo ||
        !this.communityInfo.address ||
        !this.communityInfo.address.postalCode
      )
        return;

      this.weatherLoading = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: `${API_WEATHER}/${this.communityInfo.address.postalCode}`
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.temperature = response.data.currentTemperature;
            this.humidity = response.data.currentHumidity;
            this.weatherCode = response.data.currentCondition.id;
          }
          // else {
          // }
          this.weatherLoading = false;
        });
    },
    async getServiceRequests() {
      if (this.requestsLoading) return;
      if (!this.communityInfo) return;
      if (!this.communityInfo.aspireId) return;

      this.requestsLoading = true;
      await this.$store
        .dispatch(GET_QUERY, {
          listName: `${API_SERVICE_REQUESTS}/Community/${this.communityInfo.aspireId}/publicNumbers`
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.communityInfo.serviceRequestsNumbers = response.data;
            // this.communityInfo.serviceRequests = response.data.$values.filter(
            //   el => !el.isInternal
            // );
          } else {
            this.$snackbar.showMessage({
              content:
                response.data ||
                "Cannot receive Service Requests. Something went wrong!",
              color: "red"
            });
          }

          this.requestsLoading = false;
        });
    },
    getServiceStatusIconPath(serviceStatus) {
      switch (serviceStatus.status) {
        case "On-Schedule":
          return "/media/assets/service-status/icons-on-schedule.png";
        case "Rain-Out":
          return "/media/assets/service-status/icons-rain-out.png";
        case "Holiday":
          return "/media/assets/service-status/icons-holiday.png";
        case "Conflict":
          return "/media/assets/service-status/icons-conflict.png";
      }
    },
    setStartingAfter() {
      if (this.userInvoices && this.userInvoices.length > 0) {
        this.startingAfter = this.userInvoices[this.userInvoices.length - 1].id;
        this.endingBefore = "";
      }
    },
    setEndingBefore() {
      if (this.userInvoices && this.userInvoices.length > 0) {
        this.endingBefore = this.userInvoices[0].id;
        this.startingAfter = "";
      }
    },

    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
    },
    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      return dateFormatter.formatDateUs(date);
      // return moment.utc(date).format("MM/DD/YYYY");
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    }
  },
  computed: {
    isResident() {
      return this.userInfo?.profile?.aspireRole == "Tenant/Resident";
    }
  }
};
</script>

<style lang="scss" scoped>
a.link.router-link-active button,
a.link.router-link-exact-active button,
a.link button:hover {
  background-color: #df6526 !important;
}

a.link button {
  background-color: #37bcdf !important;
}
</style>
