var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"service-box",attrs:{"outlined":"","elevation":"0"}},[_c('h6',{staticClass:"orange-bg white--text text-center py-1"},[_vm._v("Service Center")]),_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","lg":"4","md":"6","xs":"12"}},[_c('div',[(
                _vm.permissions &&
                  _vm.permissions.ninetyDayPlan &&
                  _vm.permissions.ninetyDayPlan.canView
              )?_c('router-link',{attrs:{"to":("/customerView/" + _vm.id + "/plans")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"service-box-link",class:[
                  isActive && 'service-box-item-active',
                  isExactActive && 'service-box-item-active'
                ],attrs:{"href":href},on:{"click":navigate}},[_vm._v(" Quarterly Plans ")])]}}],null,false,2423536647)}):_vm._e()],1),_c('div',[(
                _vm.permissions &&
                  _vm.permissions.orangeReport &&
                  _vm.permissions.orangeReport.canView
              )?_c('router-link',{attrs:{"to":("/customerView/" + _vm.id + "/orange-reports")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"service-box-link",class:[
                  isActive && 'service-box-item-active',
                  isExactActive && 'service-box-item-active'
                ],attrs:{"href":href},on:{"click":navigate}},[_vm._v(" Orange Reports ")])]}}],null,false,2323571211)}):_vm._e()],1),_c('div',[(
                _vm.permissions &&
                  _vm.permissions.message &&
                  _vm.permissions.message.canView
              )?_c('router-link',{attrs:{"to":("/customerView/" + _vm.id + "/messages")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"service-box-link",class:[
                  isActive && 'service-box-item-active',
                  isExactActive && 'service-box-item-active'
                ],attrs:{"href":href},on:{"click":navigate}},[_vm._v(" Messages ")])]}}],null,false,3589705470)}):_vm._e()],1)]),_c('v-col',{attrs:{"cols":"12","lg":"4","md":"6","xs":"12"}},[_c('div',[(
                _vm.permissions &&
                  _vm.permissions.proposal &&
                  _vm.permissions.proposal.canView
              )?_c('router-link',{attrs:{"to":("/customerView/" + _vm.id + "/proposals")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"service-box-link",class:[
                  isActive && 'service-box-item-active',
                  isExactActive && 'service-box-item-active'
                ],attrs:{"href":href},on:{"click":navigate}},[_vm._v(" Proposals ")])]}}],null,false,1509164633)}):_vm._e()],1),_c('div',[(
                _vm.permissions &&
                  _vm.permissions.invoice &&
                  _vm.permissions.invoice.canView
              )?_c('router-link',{attrs:{"to":("/customerView/" + _vm.id + "/invoices")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"service-box-link",class:[
                  isActive && 'service-box-item-active',
                  isExactActive && 'service-box-item-active'
                ],attrs:{"href":href},on:{"click":navigate}},[_vm._v(" Invoices ")])]}}],null,false,1961832900)}):_vm._e()],1)]),_c('v-col',{attrs:{"cols":"12","lg":"4","md":"6","xs":"12"}},[_c('div',[(
                _vm.permissions &&
                  _vm.permissions.documents &&
                  _vm.permissions.documents.canView
              )?_c('router-link',{attrs:{"to":("/customerView/" + _vm.id + "/documents")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"service-box-link",class:[
                  isActive && 'service-box-item-active',
                  isExactActive && 'service-box-item-active'
                ],attrs:{"href":href},on:{"click":navigate}},[_vm._v(" Documents ")])]}}],null,false,329769370)}):_vm._e()],1),_c('div',[(
                _vm.permissions &&
                  _vm.permissions.manageUsers &&
                  _vm.permissions.manageUsers.canAccess
              )?_c('router-link',{attrs:{"to":("/customerView/" + _vm.id + "/manage-portal-access")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"service-box-link",class:[
                  isActive && 'service-box-item-active',
                  isExactActive && 'service-box-item-active'
                ],attrs:{"href":href},on:{"click":navigate}},[_vm._v(" Manage Users ")])]}}],null,false,1815848773)}):_vm._e()],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }