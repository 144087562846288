<template>
  <div>
    <v-row v-if="accountManager">
      <v-col cols="2" md="1">
        <v-avatar size="50" color="primary">
          <span
            v-if="!communityInfo.accountManager.photo"
            class="white--text headline"
            >{{ initials }}</span
          >
          <img
            v-else
            :src="communityInfo.accountManager.photo.fileUrl"
            :alt="communityInfo.accountManager.fullName"
          />
        </v-avatar>
      </v-col>
      <v-col class="d-flex align-center">
        <h6>
          Your Landscape Account Manager:
          <span class="font-weight-bold">{{
            communityInfo.accountManager.fullName
          }}</span
          >&nbsp;
          <router-link
            v-if="showMessageAccountManagerArea()"
            class="blue--text"
            :to="
              `/customerView/${communityInfo.id}/messages/new?recipient=${communityInfo.id}`
            "
          >
            Message {{ communityInfo.accountManager.fullName }}
          </router-link>

          &nbsp;
          <span v-if="showPhone()">{{
            communityInfo.accountManager.phone
          }}</span>
        </h6>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import stringFormatter from "@/core/helpers/stringFormatter";
import Mgr from "@/core/services/security.service";

export default {
  name: "AccountManagerInfo",
  data: () => ({
    mgr: new Mgr(),
    userInfo: null
  }),
  props: {
    communityInfo: Object
  },
  async mounted() {
    await this.mgr.getUser().then(userInfo => (this.userInfo = userInfo));
  },
  methods: {
    showPhone() {
      return this.userInfo?.profile.aspireRole == "Property Manager";
    },
    showMessageAccountManagerArea() {
      return (
        this.userInfo &&
        (this.userInfo.profile.aspireRole == "Property Manager" ||
          this.userInfo.profile.aspireRole == "Landscape Committee" ||
          this.userInfo.profile.aspireRole == "Board Member")
      );
    },
    
  },
  computed: {
    initials() {
      return stringFormatter.initials(
        this.communityInfo?.accountManager?.firstName,
        this.communityInfo?.accountManager?.lastName
      );
    },
    accountManager() {
      return this.communityInfo?.accountManager;
    }

    // "Property Manager",
    // "Board Member",
    // "Landscape Committee"
    // "Tenant/Resident",
    // "Tenant/Resident - Neighborhood",
  }
};
</script>
