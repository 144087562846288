var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-skeleton-loader',{staticClass:"mx-auto ml-2 mr-2",attrs:{"type":"card","loading":_vm.loading}},[_c('CommunityInfo',{attrs:{"communityInfo":_vm.communityInfo},on:{"onRelatedCommunityChanged":_vm.showRelatedCommunity}}),_c('InternalBreadcrumbs',{attrs:{"breadcrumbs":_vm.breadcrumbs}}),(
        _vm.permissions && _vm.permissions.documents && _vm.permissions.documents.canView
      )?_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("Documents")]),(
          _vm.permissions &&
            _vm.permissions.documents &&
            _vm.permissions.documents.canUpload
        )?_c('v-card-actions',[_c('router-link',{attrs:{"to":("/customerView/" + _vm.customerId + "/documents/new")}},[_c('v-btn',{staticClass:"orange-bg white--text",attrs:{"small":""}},[_vm._v(" Upload New Document ")])],1)],1):_vm._e(),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.records,"item-key":"id","loading":_vm.isLoading,"server-items-length":_vm.totalRecords,"options":_vm.options,"loading-text":"Loading... Please wait"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.fileName",fn:function(ref){
        var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":item.fileUrl}},[_vm._v(_vm._s(item.fileName))])]}}],null,true)})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }