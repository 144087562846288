<template>
  <div>
    <h6 class="font-weight-bold">
      Community Landscape Quality Sentiment
    </h6>
    <div class="d-flex">
      <div style="width: 200px;">
        <v-rating
          v-model="communityInfo.sentiment.value"
          hover
          half-increments
          length="5"
          readonly
        >
          <template v-slot:item="props">
            <img
              height="50"
              width="40"
              :src="
                props.isFilled
                  ? 'media/assets/active-leaf.png'
                  : props.isHalfFilled
                  ? 'media/assets/half-leaf.png'
                  : 'media/assets/inactive-leaf.png'
              "
            />
          </template>
        </v-rating>
        <v-slider
          v-if="
            permissions &&
              permissions.communitySentiment &&
              permissions.communitySentiment.canEdit
          "
          v-model="communityInfo.sentiment.value"
          step="0.5"
          min="0"
          max="5"
          thumb-label
          ticks
          color="orange"
          track-color="orange"
          thumb-color="blue"
          @change="onSentimentChange"
          @start="onStart"
        ></v-slider>
      </div>
      <div class="d-flex ml-4 align-center justify-center">
        <p class="text-h4 font-weight-black">
          {{ communityInfo.sentiment.value }} / 5
        </p>
      </div>
    </div>
    <v-dialog v-model="sentimentDialog" persistent max-width="400">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-textarea
            v-model="sentimentReason"
            label="Please, briefly describe the reason(s) for this rating:"
            :error-messages="sentimentReasonErrors"
            @input="$v.sentimentReason.$touch()"
            @blur="$v.sentimentReason.$touch()"
            required
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="messageProcessing"
            color="green darken-1"
            text
            @click="sendMessage()"
          >
            Submit
          </v-btn>
          <v-btn color="gray darken-1" text @click="cancelSentimentChange">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  POST_DATA,
  API_CUSTOMERS,
  API_MESSAGES
} from "@/core/store/crud.module";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import permissionsHelper from "@/core/helpers/permissionsHelper";
// import moment from "moment";
// import crud from "@/core/helpers/crud";

export default {
  mixins: [validationMixin],
  name: "CommunitySentiment",
  props: {
    communityInfo: Object
  },
  validations() {
    return {
      sentimentReason: {
        required
      }
    };
  },
  data: () => ({
    permissions: [],
    sentimentReason: "",
    messageProcessing: false,
    sentimentDialog: false,
    startValue: 0,
    endValue: 0,

    newRequest: {
      communityName: "",
      title: "",
      requestType: "",
      description: "",
      isInternal: false,
      attachments: [],
      priority: "",
      assignedTo: "",
      targetCompletitioDate: null
    }

    // requestTypeItems: [],
    // priorityItems: [],
    // serviceRequestProcessing: false,
    // loadingPriorityItems: false,
    // loadingServiceRequestTypes: false
  }),
  async mounted() {
    
  },
  async created() {
    // this.getServiceRequestPriorities();
    // this.getServiceRequestTypes();
    await permissionsHelper.getPermissions().then(this.getPermissions);
  },
  methods: {
    getPermissions(permissionsObj) {
      this.permissions = permissionsObj;
    },
    sendMessage() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      let newMessage = {
        isNew: true,
        // sendTo: [],
        subject: this.communityInfo.name + " rated 3.5 or less",
        body:
          this.communityInfo.name +
          " rated " +
          this.endValue +
          ". The reason: " +
          this.sentimentReason,
        attachments: [],
        isActionRequired: false,
        recipients: [{ id: this.communityInfo.accountManager.id }]
      };

      this.messageProcessing = true;

      let self = this;
      this.$store
        .dispatch(POST_DATA, {
          listName: `${API_MESSAGES}`,
          data: newMessage
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            self.snackbarContent = "Message has been sent";

            self.saveSentiment(self.endValue);
          } else {
            self.snackbarContent = response.data;
            self.snackbarColor = "red";
          }
          self.$snackbar.showMessage({
            content: self.snackbarContent,
            color: self.snackbarColor
          });

          self.messageProcessing = false;
          self.sentimentDialog = false;
        });
    },
    cancelSentimentChange() {
      this.sentimentDialog = false;
      this.communityInfo.sentiment.value = this.startValue;
    },
    saveSentiment(newValue) {
      this.$store
        .dispatch(POST_DATA, {
          listName: `${API_CUSTOMERS}/${this.communityInfo.id}/sentiment`,
          data: {
            value: newValue,
            modified: new Date()
          }
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.snackbarContent = "Sentiment has been updated successfully";
          } else {
            this.snackbarContent = response.data;
            this.snackbarColor = "red";
          }
          this.$snackbar.showMessage({
            content: this.snackbarContent,
            color: this.snackbarColor
          });

          this.confirmDialog = true;
        });
    },

    onSentimentChange(newValue) {
      this.endValue = newValue;
      this.sentimentDialog = newValue <= 3.5;

      if (!this.sentimentDialog) this.saveSentiment(newValue);
    },

    onStart(startValue) {
      this.startValue = startValue;
    }
  },
  computed: {
    sentimentReasonErrors() {
      const errors = [];
      if (!this.$v.sentimentReason.$dirty) return errors;
      !this.$v.sentimentReason.required && errors.push("Required.");
      return errors;
    }
  }
};
</script>

<style></style>
